<template>
    <div>main2</div>

</template>

<script>
    export default {
        name: "main2"
    }
</script>

<style scoped>

</style>
